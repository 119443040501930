import api from "../../../services/api.service"


export const realEstateModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        fileTags(state, getters, rootState, rootGetters) {
            return [
                {value: "Stromvertrag", title: "Stromvertrag", icon: "fas fa-file-contract"},
                {value: "Grundbuchauszug", title: "Grundbuchauszug", icon: "fas fa-file-contract"},
                {value: "Kundenrechnung", title: "Kundenrechnung", icon: "fas fa-file-contract"},
                {value: "Kommissionsrechnung", title: "Kommissionsrechnung", icon: "fas fa-file-contract"},
                {value: "Managementrechnung", title: "Managementrechnung", icon: "fas fa-file-contract"},
                {value: "Heiz/Gasvertrag", title: "Heiz/Gasvertrag", icon: "fas fa-file-contract"},
                {value: "Flächenwidmungsplan", title: "Flächenwidmungsplan", icon: "fas fa-file-contract"},
                {value: "Bauplan", title: "Bauplan", icon: "fas fa-file-contract"},
                {value: "Internetrechnung", title: "Internetrechnung", icon: "fas fa-file-contract"},
                {value: "Reinigungskosten", title: "Reinigungskosten", icon: "fas fa-file-contract"},
                {value: "Flat-Introduction", title: "Flat-Introduction", icon: "fas fa-file-contract"},
                {value: "CheckIn details", title: "CheckIn details", icon: "fas fa-file-contract"},
                {value: "General information", title: "General information", icon: "fas fa-file-contract"},
                {value: "Sonstiges", title: "Sonstiges", icon: "fas fa-file-contract"},
            ]
        },
        canReadActiveRealEstates(state, getters, rootState, rootGetters) {
            return !rootGetters['user/canRead']('real_estate_active');
        },
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },
        allStatus() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'NEUBAU', text: 'NEUBAU'},
                {value: 'ALTBAU', text: 'ALTBAU'},
                {value: 'BUSINESS APARTMENT', text: 'BUSINESS APARTMENT'},
                {value: 'GUTE LAGE', text: 'GUTE LAGE'},
                {value: 'INVESTITIONSOBJEKT', text: 'INVESTITIONSOBJEKT'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'BAUGENEHMIGUNG_UND_EXPOSE', text: 'BAUGENEHMIGUNG UND EXPOSE'},
                {value: 'BAUSTART_UND_ROHBAU', text: 'BAUSTART UND ROHBAU'},
                {value: 'ROHBAU_UND_INSTALLATIONEN', text: 'ROHBAU UND INSTALLATIONEN'},
                {value: 'BAUARBEITEN_ABGESCHLOSSEN', text: 'BAUARBEITEN ABGESCHLOSSEN'},
                {value: 'SCHLUESSELFERTIG', text: 'SCHLUESSELFERTIG'}
            ]
        },
        statusVariants() {
            let statusVariants = {
                "NEW": "primary",
                "EXPOSE": "warning",
                "BAUGENEHMIGUNG_UND_EXPOSE": "warning",
                "BAUSTART_UND_ROHBAU": "warning",
                "ROHBAU_UND_INSTALLATIONEN": "warning",
                "BAUARBEITEN_ABGESCHLOSSEN": "success",
                "SCHLUESSELFERTIG": "success",
                "NEUBAU": "success",
                "ALTBAU": "success",
            };
            return (status) => {
                return statusVariants[status] != null ? statusVariants[status] : "primary";
            }
        },
        getPrices() {
            return (realEstate) => {
                let prices = realEstate.value;
                return prices.basePricePerNight != null ? "Preis pro Nacht: €" + prices.basePricePerNight :0;
               /* if (prices.rentTotal != null)
                    return "Miete: €" + prices.rentTotal;
                else if (prices.operatingCost != null)
                    return "Miete: €" + (prices.rentNetto + prices.operatingCost);
                else if (prices.rentNetto != null)
                    return "Miete: €" + prices.rentNetto;
                else if (prices.priceBrutto != null)
                    return "Kaufpreis: €" + prices.priceBrutto;
                else if (prices.price != null)
                    return "Kaufpreis: €" + prices.price;
                else return ""*/
            }
        },
        getFeaturedStatus() {
            return (realEstate) => {
                if (realEstate.advertise == null || realEstate.advertise === false)
                    return "Nicht inseriert";

                else if (realEstate.advertise === true && realEstate.featureStatus == null)
                    return "Einfaches Inserat";

                else (realEstate.featureStatus != null)
                {
                    if (realEstate.featureStatus.propertyPage === true)
                        return "Property page";
                    if (realEstate.featureStatus.searchPage === true)
                        return "Searchpage";
                    if (realEstate.featureStatus.homePage === true)
                        return "Homepage";

                }
                return "No info";
            }
        },
        getFullAddress() {
            return (realEstate) => {
                if (realEstate == null) return "";
                let address = "";
                if (realEstate.address != null) {
                    address += realEstate.address;
                }
                if (realEstate.house != null) {
                    address += (address !== "" ? "," : "") + realEstate.house;
                }
                if (realEstate.apt != null) {
                    address += (address !== "" ? "/" : "") + realEstate.apt;
                }
                if (realEstate.city != null) {
                    address += (address !== "" ? "," : "") + realEstate.city;
                }
                return address;
            }
        }
    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        },
    },
    actions: {
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        copySuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Real estate ID ' + id + ' copied'},
                {root: true});

        },
        copyLinkSuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Link ' + id + ' copied'},
                {root: true});

        },
        getData({state, commit, dispatch}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('realEstate/data').then(
                    (ans) => {
                        commit('updateData', ans);
                        resolve(state);
                    }, (error) => {
                        dispatch('notifications/error',
                            {
                                title: 'Getting data',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getAllRealEstates(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('realEstate/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    }, filter).then(
                        (ans) => {
                            if (ans["RealEstates"] == null)
                                return reject('Could not get realEstates ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

            })
        },
        getRealEstates(actions, {page, amount, filter, sortcolumn, sortby, buids, propIds}) {
            console.log("buids: ", buids)
            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('realEstate/getAll', {
                        page,
                        amount,
                        sortcolumn,
                        sortby,
                        buids: (buids == null || buids.length < 1) ? actions.rootGetters['data/getSelectedBranchesIds'].join(",") : buids.join(","),
                        propIds: propIds!=null?propIds.join(","):null

                    }, filter).then(
                        (ans) => {
                            if (ans["RealEstates"] == null)
                                return reject('Could not get realEstates ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

            })
        },
        getAllDisabledRealEstates(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                actions.dispatch('getData', false).finally(() => {
                    api.sendRequest('realEstate/getDisabled', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page, amount, sortcolumn, sortby
                    }, filter).then(
                        (ans) => {
                            if (ans["RealEstates"] == null)
                                return reject('Could not get realEstates ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

            })
        },


        handleTodoEvent(actions, {item, event, id}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/todo/' + id + '/' + event, {}, item).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not handle todo event');
                        actions.dispatch('notifications/success',
                            {title: 'Update Todo list', message: 'Todo event handled successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        markChannelAsExported(actions, {propertyId, id}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/markAsExported/' + propertyId + '/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not handle  event');
                        actions.dispatch('notifications/success',
                            {title: 'Update list', message: 'Event handled successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        deFeatureRealEstate(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/deFeature/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not deFeature realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate deFeatured successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        saveStaticPrices(actions, prices) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate-prices/add', {},prices).then(
                    (ans) => {

                        if (ans["Prices"] == null)
                            return reject('Could not push Prices');
                        actions.dispatch('notifications/success',
                            {title: 'Save RealEstate Prices', message: 'RealEstate Prices saved successfully'},
                            {root: true});
                        resolve(ans["Prices"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
   archiveRealEstate(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/archive/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not archive realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate archived successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        getExcelTemplateUrl(actions) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer)
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'excel/download/template/realEstate?' + query);
            });
        },
        uploadExcel(actions, {file, params}) {
            return new Promise(function (resolve, reject) {
                api.uploadFile('api/excel', file, "uploadFileAndInsertData",
                    {table: "RealEstate", params: btoa(JSON.stringify(params || {}))}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Uploading file', message: 'File uploaded successfully'},
                            {root: true});

                        if (ans["TransformedData"] == null)
                            return reject('Could not add real estates ');
                        resolve();
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Uploading file',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            });
        },
        copyToNextExpensesRealEstate(actions) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/bulkExpenses/', {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not copy expenses');
                        actions.dispatch('notifications/success',
                            {title: 'Copy  RealEstate expenses', message: 'RealEstate expenses copied successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        activateRealEstate(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/activate/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not activate realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate activated successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addExpenses(actions, {id, expenses}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/addExpenses/' + id, {}, expenses).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate expenses added successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Adding expense',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }, updateExpenses(actions, {id, expenses}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/updateExpenses/' + id, {}, expenses).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate expenses updated successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Updating expense',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }, deleteExpenses(actions, {id, expenseId}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/deleteExpenses/' + id + '/' + expenseId, {}, null).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not add realEstate expenses');
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate expenses added successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        bulkDisable(actions, ids) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/bulkDisable', {}, ids).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not disable realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate disabled successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        copyRealEstate(actions, {id, duplicate}) {
            console.log("selectedRealEstateId in vuex: ", id)

            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/copyRealEstate/' + id, {}, duplicate).then(
                    (ans) => {

                        if (ans["id"] == null)
                            return reject('Could not copy realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Copy RealEstate', message: 'RealEstate copied successfully'},
                            {root: true});
                        resolve(ans["id"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Copy %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        fetchRealEstateFromHostaway(actions, {buid, hostawayId, ownerId, buildingId}) {

            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/fetchListing/' + buid + '/' + hostawayId + '/' + ownerId + '/' + buildingId, {}).then(
                    (ans) => {

                        if (ans["Listing"] == null)
                            return reject('Could not fetch realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Hostaway', message: 'RealEstate fetched successfully'},
                            {root: true});
                        resolve(ans["Listing"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Fetching %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        fetchAllRealEstateFromHostaway(actions, {buid}) {

            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/fetchAllListings/' + buid, {}).then(
                    (ans) => {

                        if (ans["Listings"] == null)
                            return reject('Could not fetch realEstates');
                        actions.dispatch('notifications/success',
                            {title: 'Hostaway', message: 'RealEstates fetched successfully'},
                            {root: true});
                        resolve(ans["Listings"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Fetching %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        justimmoUpdate(actions, {buid}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('justImmo/updateFromJustimmo/' + buid, {}).then(
                    (ans) => {

                        if (ans["Status"] == null)
                            return reject('Could not update realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'RealEstate justimmo update', message: 'RealEstates updated successfully'},
                            {root: true});
                        resolve(ans["Status"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Update %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject()
                    })
            })
        },
        featureRealEstate(actions, {id, featureType}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/feature/' + id, {featureType}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not feature realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'RealEstate feature', message: 'RealEstate advertised/featured successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Update %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject()
                    })
            })
        },
        channelExport(actions, {id, request}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/channelExport/' + id, null,request).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not feature realEstate');
                        actions.dispatch('notifications/success',
                            {
                                title: 'RealEstate export to channel',
                                message: 'RealEstate export request sent successfully'
                            },
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Request %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject()
                    })
            })
        },
        fetchHostawayContracts(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/fetchReservationHostaway/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not fetch reservations from hostaway ');

                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        bulkAdvertise(actions, {ids, featureType}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/bulkAdvertise', {featureType}, ids).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not advertise/קספםרא realEstate');
                        actions.dispatch('notifications/success',
                            {
                                title: 'Advertise RealEstate',
                                message: 'RealEstate/s advertised/exported successfully'
                            },
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Export/Advertisement %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                    })
            })
        },

        bulkEnable(actions, ids) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/bulkEnable', {}, ids).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not enable realEstate');
                        actions.dispatch('notifications/success',
                            {title: 'Update RealEstate', message: 'RealEstate activated successfully'},
                            {root: true});
                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },


        getRealEstateById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/id/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not get realEstates ');

                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        getRealEstateReservationCountById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/reservationCount/' + id, {}).then(
                    (ans) => {

                        if (ans["RealEstate"] == null)
                            return reject('Could not get realEstates ');

                        resolve(ans["RealEstate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },


        updateRealEstate(actions, realEstate) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/update', null, realEstate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update realEstate', message: 'RealEstate updated successfully'},
                            {root: true});

                        if (ans["RealEstateId"] == null)
                            return reject('Could not update realEstate ');

                        resolve(ans["RealEstateId"]);
                    }, (error) => {
                        const errorMessage = error.response && error.response.data && error.response.data.message
                            ? error.response.data.message
                            : 'An error occurred'; // Fallback message

                        console.log('error', errorMessage); // Log the actual error message
                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Update %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.response.data.message : error)
                            },
                            {root: true});

                        reject(error.message)
                    })
            })
        },

        updateExportRealEstate(actions, realEstate) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/updateExport', null, realEstate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update realEstate', message: 'RealEstate updated successfully'},
                            {root: true});

                        if (ans["RealEstateId"] == null)
                            return reject('Could not update realEstate ');

                        resolve(ans["RealEstateId"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: {text: 'Update %s', arguments: ['failed']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});

                        reject(error.message)
                    })
            })
        },
        addRealEstate(actions, realEstate) {
            return new Promise((resolve, reject) => {
                api.sendRequest('realEstate/add', null, realEstate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add realEstate', message: 'RealEstate created successfully'},
                            {root: true});

                        if (ans["RealEstateId"] == null)
                            return reject('Could not create realEstate ');

                        resolve(ans["RealEstateId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getRealEstateMetaBase(actions, {type, category}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/meta', {type, category}).then(
                    (ans) => {

                        if (ans["MetaBase"] == null)
                            return reject('Could not get realEstates ');

                        resolve(ans["MetaBase"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

    },
};
